var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('scroll-to-top'),(_vm.backgroundImages && _vm.backgroundImages.length > 0)?_c('div',{staticClass:"background-image",style:(`background-image: url('${_vm.backgroundImages[_vm.imageIndex].src}'); z-index: 1;`)}):_vm._e(),_c('div',{staticClass:"v-overlay__scrim",staticStyle:{"opacity":"0.46","background-color":"rgb(33, 33, 33)","border-color":"rgb(33, 33, 33)","z-index":"2"}}),_c('img',{staticClass:"kik-logo",class:{
      'kik-logo-sm': _vm.$vuetify.breakpoint.smOnly,
      'kik-logo-xs': _vm.$vuetify.breakpoint.xsOnly
    },style:(_vm.$vuetify.breakpoint.xlOnly ? 'position: fixed;' : ''),attrs:{"src":"https://www.kik.ee/sites/default/files/kik_est_logo__2.png","alt":"kik logo"},on:{"click":_vm.openKikWindow}}),_c('div',{staticClass:"image-info white--text",style:(_vm.$vuetify.breakpoint.xlOnly ? 'position: fixed;' : '')},[(_vm.imageIndex === 0 || _vm.imageIndex === 2)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("about.imageAuthor"))+": "),_c('b',[_vm._v("Gennadi Baranov")])]):_c('div',[_vm._v(_vm._s(_vm.$t("about.imageAuthor"))+": "),_c('b',[_vm._v("Peeter Koll")]),_vm._v(" (2018)")])]),_c('v-container',{staticStyle:{"max-width":"1000px"}},[_c('v-card',{staticClass:"ma-3 user-manual-card pa-3",class:{
        'custom-mb-xs': _vm.$vuetify.breakpoint.smAndDown,
        'custom-mb-md': _vm.$vuetify.breakpoint.mdAndUp
      },staticStyle:{"z-index":"3"},attrs:{"flat":""}},[_c('span',{staticClass:"project-version"},[_vm._v("("+_vm._s(_vm.projectVersion)+")")]),(_vm.$route.name !== 'UserManual')?_c('v-card-title',{class:{
          'display-1': _vm.$vuetify.breakpoint.smAndDown,
          'display-2': _vm.$vuetify.breakpoint.mdOnly,
          'display-3': _vm.$vuetify.breakpoint.lgAndUp
        }},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]):_c('v-card-title',{staticClass:"display-3"},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }